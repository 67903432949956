// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-index-js": () => import("./../src/pages/authors/index.js" /* webpackChunkName: "component---src-pages-authors-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-us-page-about-us-page-js": () => import("./../src/templates/about-us-page/about-us-page.js" /* webpackChunkName: "component---src-templates-about-us-page-about-us-page-js" */),
  "component---src-templates-author-posts-author-posts-js": () => import("./../src/templates/author-posts/AuthorPosts.js" /* webpackChunkName: "component---src-templates-author-posts-author-posts-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-careers-page-careers-page-js": () => import("./../src/templates/careers-page/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-careers-page-js" */),
  "component---src-templates-case-study-page-case-study-page-js": () => import("./../src/templates/case-study-page/case-study-page.js" /* webpackChunkName: "component---src-templates-case-study-page-case-study-page-js" */),
  "component---src-templates-competence-page-competence-page-js": () => import("./../src/templates/competence-page/competence-page.js" /* webpackChunkName: "component---src-templates-competence-page-competence-page-js" */),
  "component---src-templates-competence-single-page-competence-single-page-js": () => import("./../src/templates/competence-single-page/competence-single-page.js" /* webpackChunkName: "component---src-templates-competence-single-page-competence-single-page-js" */),
  "component---src-templates-contacts-page-contacts-page-js": () => import("./../src/templates/contacts-page/contacts-page.js" /* webpackChunkName: "component---src-templates-contacts-page-contacts-page-js" */),
  "component---src-templates-digital-transformation-page-digital-transformation-page-js": () => import("./../src/templates/digital-transformation-page/digital-transformation-page.js" /* webpackChunkName: "component---src-templates-digital-transformation-page-digital-transformation-page-js" */),
  "component---src-templates-digital-transformation-static-page-digital-transformation-static-page-js": () => import("./../src/templates/digital-transformation-static-page/digital-transformation-static-page.js" /* webpackChunkName: "component---src-templates-digital-transformation-static-page-digital-transformation-static-page-js" */),
  "component---src-templates-event-page-event-page-js": () => import("./../src/templates/event-page/event-page.js" /* webpackChunkName: "component---src-templates-event-page-event-page-js" */),
  "component---src-templates-index-page-index-page-js": () => import("./../src/templates/index-page/index-page.js" /* webpackChunkName: "component---src-templates-index-page-index-page-js" */),
  "component---src-templates-livion-page-livion-page-js": () => import("./../src/templates/livion-page/livion-page.js" /* webpackChunkName: "component---src-templates-livion-page-livion-page-js" */),
  "component---src-templates-press-page-press-page-js": () => import("./../src/templates/press-page/press-page.js" /* webpackChunkName: "component---src-templates-press-page-press-page-js" */),
  "component---src-templates-resources-page-resources-page-js": () => import("./../src/templates/resources-page/resources-page.js" /* webpackChunkName: "component---src-templates-resources-page-resources-page-js" */),
  "component---src-templates-static-page-static-page-js": () => import("./../src/templates/static-page/static-page.js" /* webpackChunkName: "component---src-templates-static-page-static-page-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

